import React from "react";

const VehicleDetails = ({ vehicleDetails }) => {
  if (!vehicleDetails) return null;

  return (
    <div className="mb-6 bg-gray-800 rounded-lg mt-10 p-4 sm:p-6">
      <h3 className="text-xl sm:text-2xl font-semibold text-gray-200 mb-4 border-b border-gray-600 pb-2">
        Vehicle Details
      </h3>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
        <div className="bg-gray-700 p-3 sm:p-4 rounded-lg">
          <p className="text-gray-300 font-medium">Make:</p>
          <p className="text-gray-400">{vehicleDetails.make}</p>
        </div>
        <div className="bg-gray-700 p-3 sm:p-4 rounded-lg">
          <p className="text-gray-300 font-medium">Registration No:</p>
          <p className="text-gray-400">{vehicleDetails.registrationNumber}</p>
        </div>
        <div className="bg-gray-700 p-3 sm:p-4 rounded-lg">
          <p className="text-gray-300 font-medium">Colour:</p>
          <p className="text-gray-400">{vehicleDetails.colour}</p>
        </div>
        <div className="bg-gray-700 p-3 sm:p-4 rounded-lg">
          <p className="text-gray-300 font-medium">Fuel Type:</p>
          <p className="text-gray-400">{vehicleDetails.fuelType}</p>
        </div>
        <div className="bg-gray-700 p-3 sm:p-4 rounded-lg">
          <p className="text-gray-300 font-medium">Year of Manufacture:</p>
          <p className="text-gray-400">{vehicleDetails.yearOfManufacture}</p>
        </div>
        <div className="bg-gray-700 p-3 sm:p-4 rounded-lg">
          <p className="text-gray-300 font-medium">Wheel Plan:</p>
          <p className="text-gray-400">{vehicleDetails.wheelplan}</p>
        </div>
        <div className="bg-gray-700 p-3 sm:p-4 rounded-lg">
          <p className="text-gray-300 font-medium">Tax Status:</p>
          <p className="text-gray-400">{vehicleDetails.taxStatus}</p>
        </div>
        <div className="bg-gray-700 p-3 sm:p-4 rounded-lg">
          <p className="text-gray-300 font-medium">Tax Due Date:</p>
          <p className="text-gray-400">{vehicleDetails.taxDueDate}</p>
        </div>
        <div className="bg-gray-700 p-3 sm:p-4 rounded-lg">
          <p className="text-gray-300 font-medium">MOT Status:</p>
          <p className="text-gray-400">{vehicleDetails.motStatus}</p>
        </div>
        <div className="bg-gray-700 p-3 sm:p-4 rounded-lg">
          <p className="text-gray-300 font-medium">MOT Expiry Date:</p>
          <p className="text-gray-400">{vehicleDetails.motExpiryDate}</p>
        </div>
        <div className="bg-gray-700 p-3 sm:p-4 rounded-lg">
          <p className="text-gray-300 font-medium">Engine Capacity:</p>
          <p className="text-gray-400">{vehicleDetails.engineCapacity} cc</p>
        </div>
        <div className="bg-gray-700 p-3 sm:p-4 rounded-lg">
          <p className="text-gray-300 font-medium">CO2 Emissions:</p>
          <p className="text-gray-400">{vehicleDetails.co2Emissions} g/km</p>
        </div>
        <div className="bg-gray-700 p-3 sm:p-4 rounded-lg">
          <p className="text-gray-300 font-medium">Type Approval:</p>
          <p className="text-gray-400">{vehicleDetails.typeApproval}</p>
        </div>
        <div className="bg-gray-700 p-3 sm:p-4 rounded-lg">
          <p className="text-gray-300 font-medium">Date of Last V5C Issued:</p>
          <p className="text-gray-400">{vehicleDetails.dateOfLastV5CIssued}</p>
        </div>
        <div className="bg-gray-700 p-3 sm:p-4 rounded-lg">
          <p className="text-gray-300 font-medium">
            Month of First Registration:
          </p>
          <p className="text-gray-400">
            {vehicleDetails.monthOfFirstRegistration}
          </p>
        </div>
      </div>
    </div>
  );
};

export default VehicleDetails;
import React, { useState } from "react";
import { useAuthStore } from "../store/store";
import axios from "axios";
import { toast } from "react-toastify";

export default function UploadCSV() {
  const { uploadCSVFile } = useAuthStore();
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("");

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
    setFileName(selectedFile.name);
  };

  const handleUpload = async () => {
    try {
      const formData = new FormData();
      formData.append("csvFile", file);

      const response = await axios.post(uploadCSVFile, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      console.log("Response from server:", response.data);

      if (response.status === 200) {
        toast.success("Data uploaded successfully", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
          style: {
            borderRadius: "10px",
          },
        });

        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        toast.error("Upload failed", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
          style: {
            borderRadius: "10px",
          },
        });
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      toast.error("Error uploading file", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        style: {
          borderRadius: "10px",
        },
      });
    }
  };

  return (
    <section className="flex items-center justify-center min-h-screen">
      <div className="flex flex-col items-center justify-center px-4 py-8 mx-auto sm:px-6 lg:px-8">
        <div className="w-full max-w-md p-6 bg-white rounded-lg shadow dark:bg-gray-800 dark:border dark:border-gray-700">
          <h2 className="mb-8 text-lg font-semibold text-center">Upload CSV File</h2>
          <input
            type="file"
            name="csvFile"
            accept=".csv"
            onChange={handleFileChange}
            className="block w-full px-3 py-2 mb-4 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
          {fileName && <p className="mb-4 text-sm text-gray-700">Selected File: {fileName}</p>}
          <button
            onClick={handleUpload}
            className="w-full px-4 py-2 text-white bg-slate-800 rounded-md hover:bg-slate-600 transition duration-300 ease-in-out"
          >
            Upload
          </button>
        </div>
      </div>
    </section>
  );
}

import React, { useState } from "react";
import axios from "axios";
import { useAuthStore } from "../store/store";

export default function CommentForm({ taskId, onCommentAdded }) {
  const [comment, setComment] = useState("");
  const [file, setFile] = useState(null);
  const { user, tasks } = useAuthStore();

  const handleCommentChange = (e) => {
    setComment(e.target.value);
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };


  const handleCommentSubmit = async (event) => {
    event.preventDefault();

    const userId = user?._id;
    console.log(userId)
    const formData = new FormData();
    formData.append("body", comment);
    formData.append("userId", userId);
    if (file) {
      formData.append("file", file);
    }

    try {
      await axios.post(
        `${tasks}/${taskId}/comments/user`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      alert("Comment added successfully");
      setComment("");
      setFile(null);
      if (onCommentAdded) {
        onCommentAdded(); 
      }
    } catch (error) {
      console.error("Error adding comment:", error);
      alert("Failed to add comment");
    }
  }; 

  return (
    <div className="mt-6">
      <label className="block text-gray-400 text-xs mb-2" htmlFor="comment">
        Add a comment
      </label>
      <textarea
        id="comment"
        value={comment}
        onChange={handleCommentChange}
        className="w-full bg-gray-800 border border-gray-600 text-white py-3 px-4 rounded"
        placeholder="Enter your comment..."
      />

      <label className="block text-gray-400 text-xs mb-2 mt-4" htmlFor="file">
        Attach a file
      </label>
      <input
        type="file"
        id="file"
        onChange={handleFileChange}
        className="w-full bg-gray-800 border border-gray-600 text-white py-3 px-4 rounded"
      />

      <button
        onClick={handleCommentSubmit}
        type="button"
        className="mt-4 text-white bg-gradient-to-r from-teal-400 via-teal-500 to-teal-600 hover:bg-gradient-to-br font-medium rounded-lg text-sm px-5 h-12 text-center"
      >
        Submit Comment and File
      </button>
    </div>
  );
}
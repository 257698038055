import { create } from "zustand"
// import getApiUrl from "../endpoint";


// const endPoints = "http://localhost:7000";
 const endPoints = "https://api.twosvn.co.uk";

export const useAuthStore = create((set) => ({

  isLoggedIn: localStorage.getItem("isLoggedIn") === "true",
  userEmail: localStorage.getItem("userEmail") || '',
  user: JSON.parse(localStorage.getItem('user')) || {},
  adminLoginUrl: `${endPoints}/api/v1/admin`,
  adminRegisterUrl: `${endPoints}/api/v1/admin/signup`,
  forgotPassword: `${endPoints}/api/v1/forgot_password`,
  resetPassword: `${endPoints}/api/v1/forgot_password/reset`,
  userLoginUrl: `${endPoints}/api/v1/user`,
  userLogOutUrl: `${endPoints}/api/v1/user/logout`,
  adminLogOutUrl: `${endPoints}/api/v1/admin/logout`,
  allUserUrl: `${endPoints}/api/v1/user/users`,
  allUserUrlGeoCode: `${endPoints}/api/v1/user/geocode?address`,
  googleMapDirectionUrl: `${endPoints}/api/v1/user/directions`,
  userRegisterUrl: `${endPoints}/api/v1/user/signup`,
  uploadCSVFile: `${endPoints}/api/v1/admin/upload-csv`,
  csvData: `${endPoints}/api/v1/admin/all-csv-data`,
  unAssignedCSVData: `${endPoints}/api/v1/admin/unassigned-csv-data`,
  csvDataID: `${endPoints}/api/v1/admin/csv-data`,
  assignTask: `${endPoints}/api/v1/admin/assign-task`,
  tasksStatus: `${endPoints}/api/v1/admin/tasks-status`,
  adminTasksStatus: `${endPoints}/api/v1/admin/tasks?status`,
  undoAssignedTask: `${endPoints}/api/v1/admin/undo-assign-task`,
  tasks: `${endPoints}/api/v1/user/tasks`,
  updateTaskStatus: `${endPoints}/api/v1/user/tasks`,
  VehicleDetailsUrl: `${endPoints}/api/v1/user/vehicle-enquiry`,
  userTasks: `${endPoints}/api/v1/user/assigned`,
  agentDetails: `${endPoints}/api/v1/user/users-with-tasks`,
  allAgent: `${endPoints}/api/v1/user/users-with-tasks`,
  notificationsUrl: `${endPoints}/api/v1/user/notifications`,
  checkClamped: `${endPoints}/api/v1/user/check-clamped-tasks`,
  checkClampedAlert: `${endPoints}/api/v1/user/check-clamped-alert`,

  setIsLoggedIn: (bool) => {
    set({ isLoggedIn: bool });
    localStorage.setItem("isLoggedIn", bool.toString());
  },

  setUserEmail: (email) => {
    set({ userEmail: email });
    localStorage.setItem("userEmail", email);
  },

  setUser: (user) => {
    set({ user });
    localStorage.setItem('user', JSON.stringify(user));
  },

  clearAuthToken: () => {
    set({ isLoggedIn: false, userEmail: '', user: {} });
    localStorage.removeItem("isLoggedIn");
    localStorage.removeItem("userEmail");
    localStorage.removeItem("user");
  }
  
})); 
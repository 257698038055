import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom';
import './App.css';
import SharedLayout from './components/SharedLayout';
import Home from './pages/Home';
import SignIn from './pages/SignIn';
import SignUp from './pages/SignUp';
import { useEffect } from 'react';

import AOS from "aos";
import "aos/dist/aos.css";
import Footer from './components/Footer';
import AdminPage from './admin/AdminPage';
import AdminLogin from './admin/AdminLogin';
import AdminRegister from './admin/AdminRegister';
import UserDashboard from './accountUser/UserDashboard';
import TaskDashboard from './accountUser/TaskDashboard';

import CompleteDetails from './admin/CompleteDetails';
import DisplayCSV from './admin/DisplayCSV';
import TaskDetails from './accountUser/TaskDetails';
import ForgotPassword from './accountUser/ForgotPassword';
import NewPassword from './accountUser/NewPassword';
import AdminForgotPassword from './admin/ForgotPassword';
import AdminNewPassword from './admin/NewPassword';
import AvailableTaskDetails from './accountUser/AvailableTaskDetails';
import CompletedTaskStatusDetails from './accountUser/CompletedTaskStatusDetails';
import AgentDetails from './admin/AgentDetails';
import AgentTaskDetails from './admin/AgentTaskDetails';

import AllAgentTaskDetails from './admin/AllAgentTaskDetails';
import Notifications from './components/Notifications';



const router = createBrowserRouter([{
  path: "/",
  element: <SharedLayout />,
  children: [
    { element: <Navigate to="/signIn" replace/>, index: true },
    { path: "/home", element: <Home /> },
    { path: "/signIn", element: <SignIn /> },
    { path: "/signUp", element: <SignUp /> },
    { path: "/footer", element: <Footer /> },
    { path: "/...adOS1...a", element: < AdminPage /> },
    { path: "/...adOS1...l", element: < AdminLogin /> },
    { path: "/...adOS1...r", element: < AdminRegister /> },
    { path: "/...adOS1...d", element: <DisplayCSV /> },
    { path: "/user-dashboard", element: < UserDashboard /> },
    { path: "/task-dashboard", element: < TaskDashboard /> },
    { path: "/notifications", element: < Notifications /> },

    // { path: "taskModal", element: <Modal /> },
    { path: "/completed-details/:id", element: <CompleteDetails /> },
    { path: "/available-task-details/:id", element: <AvailableTaskDetails /> },

    { path: "/all-agent-task-details/:taskId", element: <AllAgentTaskDetails /> },

    { path: "/completed-task-details/:taskId", element: <CompletedTaskStatusDetails /> },
    { path: "/task-details/:taskId", element: <TaskDetails /> },
    { path: "/forgotPassword", element: <ForgotPassword /> },
    { path: "/newPassword/:email", element: <NewPassword /> },
    { path: "/adminForgotPassword", element: <AdminForgotPassword /> },
    { path: "/adminNewPassword/:email", element: <AdminNewPassword /> },
    {path: "/agent/:id", element: <AgentDetails /> },
    { path: "/agent-task-details/:taskId", element: <AgentTaskDetails /> }

  ]
}])
const App = () => {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  return <RouterProvider router={router}></RouterProvider>;
};

export default App;
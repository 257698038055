import React, { useEffect, useState, useRef } from "react";
import UploadCSV from "./UploadCSV";
import DisplayCSV from "./DisplayCSV";
import { useAuthStore } from "../store/store";
import { toast } from "react-toastify";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Agents from "./Agents";
import AllAgentTasks from "./AllAgentTasks";

export default function AdminDashboard() {
  const navigate = useNavigate();

  const [selectedTab, setSelectedTab] = useState("dashboard");
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const hasWarnedRef = useRef(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const { isLoggedIn, setIsLoggedIn, adminLogOutUrl, clearAuthToken } =
    useAuthStore();

  useEffect(() => {
    if (!isLoggedIn && !hasWarnedRef.current) {
      navigate("/...adOS1...l");
      toast.warn("Please login to access the admin dashboard.", {
        position: "top-left",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      hasWarnedRef.current = true;
    }
  }, [isLoggedIn, navigate]);

  const [showConfirmation, setShowConfirmation] = useState(false);

  const logout = async () => {
    try {
      const authToken = localStorage.getItem("authToken");

      const res = await axios.get(adminLogOutUrl, {
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });

      if (res.status === 200) {
        clearAuthToken();
        setIsLoggedIn(false);
        navigate("/...adOS1...l");

        toast.success("Successfully logged out", {
          position: "top-left",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    } catch (err) {
      console.error("Error logging out:", err);
      toast.error("Failed to log out. Please try again later.", {
        position: "top-left",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };

  const handleLogout = () => setShowConfirmation(true);
  const confirmLogout = () => {
    logout();
    setShowConfirmation(false);
  };

  const cancelLogout = () => setShowConfirmation(false);

  return (
    <div className="flex flex-col lg:flex-row min-h-screen">
      {/* Sidebar */}
      <div className="w-full lg:w-64 bg-gray-900 ">
        <div className="p-4 flex items-center justify-between">
          <h2 className="text-xl font-semibold text-secondaryp">
            Admin Dashboard
          </h2>
          {/* Menu toggle button for small screens */}
          <button
            className="lg:hidden text-gray-200 focus:outline-none focus:text-white"
            onClick={toggleMenu}
          >
            {isMenuOpen ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16m-7 6h7"
                />
              </svg>
            )}
          </button>
        </div>
        {/* Sidebar menu for small screens */}
        <nav className={`p-2  ${isMenuOpen ? "block" : "hidden"} lg:block`}>
          <ul>
            <li
              className={`cursor-pointer text-secondary p-2 ${
                selectedTab === "dashboard" ? "bg-secondaryp" : ""
              }`}
              onClick={() => setSelectedTab("dashboard")}
            >
              Dashboard
            </li>
            <li
              className={`cursor-pointer text-secondary p-2 ${
                selectedTab === "csv" ? "bg-secondaryp" : ""
              }`}
              onClick={() => setSelectedTab("csv")}
            >
              All CSV Data
            </li>

            <li
              className={`cursor-pointer text-secondary p-2 ${
                selectedTab === "agents" ? "bg-secondaryp" : ""
              }`}
              onClick={() => setSelectedTab("agents")}
            >
              Agents
            </li>

            <li
              className={`cursor-pointer text-secondary p-2 ${
                selectedTab === "agentTasks" ? "bg-secondaryp" : ""
              }`}
              onClick={() => setSelectedTab("agentTasks")}
            >
              All Agents Tasks
            </li>

            <button
              onClick={handleLogout}
              className="bg-red-500 ml-2 my-5 text-white px-6 py-2 rounded-sm"
            >
              Logout
            </button>
          </ul>
        </nav>
      </div>

      {/* Main Content */}
      <div className="flex-1 bg-gray-100">
        <div className="bg-white p-4 py-10 border-b border-gray-200">
          <h1 className="text-sm text-center font-semibold text-gray-800">
            Welcome to Admin Dashboard
          </h1>
        </div>

        <div className="">
          {selectedTab === "dashboard" && (
            <div>
              <UploadCSV />
            </div>
          )}
          {selectedTab === "csv" && (
            <div className="p-5">
              <DisplayCSV />
            </div>
          )}
          {selectedTab === "agents" && (
            <div className="">
              <Agents />
            </div>
          )}

          {selectedTab === "agentTasks" && (
            <div className="p-5">
              <AllAgentTasks />
            </div>
          )}
        </div>
      </div>

      {showConfirmation && (
        <div
          style={{ zIndex: 3 }}
          className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-75"
        >
          <div className="bg-white p-8 rounded-lg shadow-lg">
            <p className="text-sm font-medium text-center  text-gray-800 mb-4">
              Are you sure you want to logout ?
            </p>
            <div className="justify-center flex items-center">
              <img
                className="h-60 ml-4 rounded-xl"
                src={require("../images/logout.jpg")}
              />
            </div>
            <div>
              <div className="flex justify-between pt-8">
                <button
                  onClick={confirmLogout}
                  className="bg-red-500 hover:bg-red-300 transition duration-300 ease-in-out text-white w-full px-4 py-3 rounded-md mr-2"
                >
                  Continue
                </button>
                <button
                  onClick={cancelLogout}
                  className="bg-gray-800 hover:bg-gray-600 transition duration-300 ease-in-out text-white w-full px-4 py-3 rounded-md"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

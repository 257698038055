import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useAuthStore } from "../store/store";
import axios from "axios";

function ForgotPassword() {
  const navigate = useNavigate();

  const { forgotPassword } = useAuthStore();
  const [inputEmail, setInputEmail] = useState("");

  const errorHandle = (err) => {
    toast.error(err, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  const forgotPass = async () => {
    if (!inputEmail) {
      errorHandle("Please enter an email");
    } else {
      toast.info("🦄 Please Wait!", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }

    const res = await axios
      .post(forgotPassword, { email: inputEmail })
      .catch((err) => {
        errorHandle(err?.response?.data);
      });

    if (res) {
      // setUser(res.data);
      console.log(res.data);
      navigate(`/adminNewPassword/${inputEmail}`);
    }
  };

  return (
    <main className="h-screen w-full bg-black ">
      <div
        className="w-full h-full opacity-40 bg-cover bg-center rounded-lg overflow-hidden relative"
        style={{
          backgroundImage: `url('https://source.unsplash.com/600x800/?business')`,
        }}
      ></div>

      <div class="bg-white h-60 mt-20 w-96 text-black absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 p-10 rounded-md shadow-lg">
        <h1 class="text-primary text-2xl font-semibold mb-2">
          Search Your Account
        </h1>
        <p class="text-gray-600 text-sm mb-4">
          Please enter your email address{" "}
        </p>
        <input
          required
          type="email"
          value={inputEmail}
          onChange={(e) => {
            setInputEmail(e.target.value);
          }}
          class="text-sm p-3 border-2 border-gray-300 rounded-md w-full mb-4"
          placeholder="Enter your email address..."
        />
        <button
          onClick={forgotPass}
          class="bg-primary w-40 text-white py-2 px-6 rounded-md hover:bg-primary-dark transition duration-300 ease-in-out"
        >
          Submit
        </button>
      </div>
    </main>
  );
}

export default ForgotPassword;

import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, Link } from "react-router-dom";
import { Spinner } from "../accountUser/Spinner";
import { FaEnvelope, FaMapMarkerAlt, FaRegAddressCard } from "react-icons/fa";
import { useAuthStore } from "../store/store";

export default function AgentDetails() {
  const { id } = useParams();
  const [agent, setAgent] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const { agentDetails } = useAuthStore();

  useEffect(() => {
    axios
      .get(`${agentDetails}/${id}`)
      .then((response) => {
        setAgent(response.data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error.message);
        setLoading(false);
      });
  }, [id]);

  if (loading)
    return (
      <div className="flex items-center justify-center h-screen">
        {" "}
        <Spinner />{" "}
      </div>
    );
  if (error)
    return (
      <div className="flex items-center justify-center h-screen">
        Error: {error}
      </div>
    );

  return (
    <div className="w-full h-full p-5 bg-gray-900">
      <div className="max-w-4xl bg-white mx-auto p-6 shadow-md rounded-lg">
        {agent && (
          <>
            <div className="mb-6">
              <h1 className="text-2xl font-bold mb-4 text-orange-400">
                {agent.user.firstName} {agent.user.lastName}
              </h1>

              <p className=" flex items-center text-lg font-medium antialiased font-sans leading-relaxed  text-inherit mb-1">
                <FaEnvelope className="text-red-500 mr-2" />
                Email:
                <span className="font-light ml-2 text-md">
                  {agent.user.email}
                </span>
              </p>

              <p className=" flex items-center text-lg font-medium antialiased font-sans leading-relaxed  text-inherit mb-1">
                <FaMapMarkerAlt className="text-green-500 mr-2" />
                Location:
                <span className="font-light ml-2 text-md">
                  {agent.user.location}
                </span>
              </p>
              <p className=" flex items-center text-lg font-medium antialiased font-sans leading-relaxed  text-inherit mb-1">
                <FaRegAddressCard className="text-purple-500 mr-2" />
                Post code:
                <span className="font-light ml-2 text-md">
                  {agent.user.postal_code}
                </span>
              </p>
            </div>
            <div>
              <h3 className="text-md text-right mr-12 font-semibold mb-4">
                Assigned Tasks:
              </h3>

              <div className="grid gap-8 sm:grid-cols-1 md:grid-cols-2 m-5">
                {agent.tasks.map((task) => (
                  <div
                    key={task._id}
                    className="relative flex flex-col my-2 bg-clip-border rounded-xl bg-white text-gray-700 shadow-md lg:h-max lg:scale-105 z-10 translate-y-0"
                  >
                    <div className="p-6 w-full font-light text-left">
                      <p className="block antialiased font-sans leading-relaxed text-sm font-normal text-inherit mb-4">
                        Title:{" "}
                        <span className="font-light text-xs">
                          Title: {task.title}
                        </span>
                      </p>
                      <p className="block font-medium antialiased font-sans leading-relaxed text-sm text-inherit mb-4">
                        Description:{" "}
                        <span className="font-light text-xs">
                          {task.description}
                        </span>
                      </p>
                      <p className="block font-medium antialiased font-sans leading-relaxed text-sm text-inherit mb-4">
                        Status:{" "}
                        <span className="font-light text-xs">
                          {" "}
                          {task.status}
                        </span>
                      </p>
                      <Link
                        to={`/agent-task-details/${task._id}`}
                        key={agent.user._id}
                      >
                        <button
                          className="text-white w-60 bg-gradient-to-r flex flex-row items-center justify-center from-teal-400 via-teal-500 to-teal-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-teal-300 dark:focus:ring-teal-800 font-medium rounded-lg text-sm px-5 h-12 text-center mb-2 sm:mb-0"
                          type="button"
                        >
                          <p className="mx-1">More Details</p>
                        </button>
                      </Link>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

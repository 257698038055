import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useAuthStore } from "../store/store";
import { Spinner } from "./Spinner";

export default function CompletedTaskStatusDetails() {

  const { tasks } = useAuthStore();
  const { taskId } = useParams();
  const [taskDetails, setTaskDetails] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchTaskDetails = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get(`${tasks}/${taskId}`);
        setTaskDetails(response.data);
      } catch (error) {
        console.error("Error fetching task details:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchTaskDetails();
  }, [taskId, tasks]);

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-800 p-5">
      {isLoading ? (
        <Spinner />
      ) : (
        <div className="w-full max-w-4xl bg-white p-8 rounded-lg shadow-lg">
          <div className="mb-6">
            <h2 className="text-3xl font-medium text-gray-800 mb-4">{taskDetails.title}</h2>
            <p className="text-gray-600">{taskDetails.description}</p>
          </div>
          <div className="overflow-x-auto">
            <table className="min-w-full bg-white border border-gray-200 rounded-lg">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-6 py-3 text-left text-sm font-medium text-gray-500 uppercase tracking-wider">
                    Key
                  </th>
                  <th className="px-6 py-3 text-left text-sm font-medium text-gray-500 uppercase tracking-wider">
                    Value
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {Object.entries(taskDetails.taskDetails).map(([key, value], index) => (
                  <tr key={index}>
                    <td className="px-6 py-4 text-sm font-medium text-gray-800">
                      {key}
                    </td>
                    <td className="px-6 py-4 text-sm text-gray-600">
                      {value}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
}

import React, { useEffect, useState } from "react";
import axios from "axios";
import { useAuthStore } from "../store/store";
import { useNavigate } from "react-router-dom";
import { BellIcon, ExclamationTriangleIcon } from "@heroicons/react/24/outline"; 

export default function Notifications() {

  const [notifications, setNotifications] = useState([]);
  const { user, notificationsUrl } = useAuthStore();
  const navigate = useNavigate();
  const userId = user._id;


  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const response = await axios.get( notificationsUrl , {
          params: { userId },
        });
        setNotifications(response.data);
        console.log(response.data)
      } catch (error) {
        console.error("Error fetching notifications:", error);
      }
    };

    fetchNotifications();
  }, [userId]);

  const handleNotificationClick = (taskId) => {
    navigate(`/task-details/${taskId}`);
  };

  return (
    <div className="p-5 bg-gray-800 max-w-3xl mx-auto">
      <h1 className="text-2xl font-bold text-gray-300 mb-6 flex items-center">
        <BellIcon className="h-8 w-8 mr-2 text-blue-300 " /> Notifications
      </h1>
      {notifications.length > 0 ? (
        notifications.map((notification) => (
          <div
            key={notification._id}
            onClick={() => handleNotificationClick(notification.task)}
            className="p-4 mb-4 bg-white shadow-md rounded-md cursor-pointer hover:bg-blue-50 transition-all duration-200 flex items-start"
          >
            <ExclamationTriangleIcon className="h-6 w-6 text-yellow-500 mr-4 mt-1" />
            <div>
              <p className="text-lg font-semibold text-gray-700">{notification.message}</p>
              <p className="text-sm text-gray-500">
                {new Date(notification.createdAt).toLocaleString("en-US", {
                  weekday: "short",
                  year: "numeric",
                  month: "short",
                  day: "numeric",
                  hour: "2-digit",
                  minute: "2-digit",
                })}
              </p>
            </div>
          </div>
        ))
      ) : (
        <p className="text-gray-600 text-center mt-10">No notifications available.</p>
      )}
    </div>
  );
}

import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { Spinner } from "../accountUser/Spinner";
import { FaEnvelope, FaMapMarkerAlt, FaRegAddressCard } from "react-icons/fa";
import { useAuthStore } from "../store/store";

export default function Agents() {
  const { allAgent, adminTasksStatus } = useAuthStore();
  const [agents, setAgents] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState("");

  useEffect(() => {
    // Fetch agents from the backend
    axios
      .get(allAgent)
      .then((response) => {
        setAgents(response.data);
        setLoading(false);
        console.log("Agents fetched:", response.data);
      })
      .catch((error) => {
        setError(error.message);
        setLoading(false);
      });
  }, [allAgent]);

  useEffect(() => {
    // Fetch tasks based on the selected status if one is selected
    if (selectedStatus) {
      axios
        .get(`${adminTasksStatus}=${selectedStatus}`)
        .then((response) => {
          if (response.data.length === 0) {
            setError("No tasks found for the selected status.");
          } else {
            setTasks(response.data);
            setError(null); // Clear any previous error
          }
          console.log("Tasks fetched:", response.data);
        })
        .catch((error) => {
          setError(error.message);
        });
    } else {
      setTasks([]);
    }
  }, [selectedStatus]);

  const displayedAgents = selectedStatus
    ? agents.filter((agent) => {
        const matchedTasks = tasks.filter(
          (task) => task.assignedUser && task.assignedUser._id === agent.user._id
        );
        return matchedTasks.length > 0;
      })
    : agents;

  useEffect(() => {
    console.log("Selected status:", selectedStatus);
    console.log("Displayed agents:", displayedAgents);
    console.log("Tasks:", tasks);
  }, [displayedAgents, selectedStatus, tasks]);

  if (loading)
    return (
      <div className="flex flex-col justify-center items-center h-screen">
        <Spinner />
      </div>
    );
  if (error)
    return (
      <div className="flex flex-col justify-center items-center h-screen">
        <p className="text-lg text-white">Error: {error}</p>
      </div>
    );

  return (
    <div className="bg-gray-800 p-5">
      <div className="flex flex-col items-center mb-8">
        <label htmlFor="status" className="text-lg font-light text-white mb-3">
          Filter by status:
        </label>
        <select
          id="status"
          value={selectedStatus}
          onChange={(e) => {
            setSelectedStatus(e.target.value);
          }}
          className="block appearance-none w-full sm:w-1/2 bg-gray-800 text-white border border-gray-600 hover:border-gray-400 px-4 py-3 pr-8 rounded-lg shadow-lg focus:outline-none focus:border-teal-500 transition duration-300"
        >
          <option value="">All Agents</option>
          <option value="completed">Completed</option>
          <option value="pif">Pif</option>
          <option value="arrangement">Arrangement</option>
          <option value="clamped">Clamped</option>
          <option value="cut">Cut</option>
        </select>
      </div>

      <div className="flex flex-wrap justify-center gap-8 m-5">
        {displayedAgents.map((agent) => (
          <div
            key={agent.user._id}
            className="relative flex w-full sm:w-72 md:w-96 flex-col my-2 bg-clip-border rounded-xl bg-white text-gray-700 shadow-md z-10 translate-y-0"
          >
            <div className="p-6 font-light text-left">
              <div className="mb-6">
                <h1 className="text-2xl font-bold mb-4 text-orange-400">
                  {agent.user.firstName} {agent.user.lastName}
                </h1>

                <p className="flex items-center text-lg font-medium antialiased font-sans leading-relaxed text-inherit mb-1">
                  <FaEnvelope className="text-red-500 mr-2" />
                  Email:
                  <span className="font-light ml-2 text-md overflow-hidden text-ellipsis whitespace-nowrap">
                    {agent.user.email}
                  </span>
                </p>

                <p className="flex items-center text-lg font-medium antialiased font-sans leading-relaxed text-inherit mb-1">
                  <FaMapMarkerAlt className="text-green-500 mr-2" />
                  Location:
                  <span className="font-light ml-2 text-md overflow-hidden text-ellipsis whitespace-nowrap">
                    {agent.user.location}
                  </span>
                </p>
                <p className="flex items-center text-lg font-medium antialiased font-sans leading-relaxed text-inherit mb-1">
                  <FaRegAddressCard className="text-purple-500 mr-2" />
                  Post code:
                  <span className="font-light ml-2 text-md overflow-hidden text-ellipsis whitespace-nowrap">
                    {agent.user.postal_code}
                  </span>
                </p>
              </div>

              {selectedStatus ? (
                <div className="flex flex-wrap justify-center gap-4">
                  {tasks
                    .filter(
                      (task) =>
                        task.assignedUser &&
                        task.assignedUser._id === agent.user._id
                    )
                    .map((task) => (
                      <div
                        key={task._id}
                        className="relative flex flex-col my-2 bg-clip-border rounded-xl bg-white text-gray-700 shadow-md z-10 translate-y-0"
                      >
                        <div className="p-6 w-full font-light text-left">
                          <p className="block antialiased font-sans leading-relaxed text-sm font-normal text-inherit mb-4">
                            Title:{" "}
                            <span className="font-light text-xs">
                              {task.title}
                            </span>
                          </p>
                          <p className="block font-medium antialiased font-sans leading-relaxed text-sm text-inherit mb-4">
                            Description:{" "}
                            <span className="font-light text-xs">
                              {task.description}
                            </span>
                          </p>
                          <p className="block font-medium antialiased font-sans leading-relaxed text-sm text-inherit mb-4">
                            Status:{" "}
                            <span className="font-light text-xs">
                              {" "}
                              {task.status}
                            </span>
                          </p>
                          <Link
                            to={`/agent-task-details/${task._id}`}
                            key={task._id}
                          >
                            <button
                              className="text-white bg-gradient-to-r flex flex-row items-center justify-center from-teal-400 via-teal-500 to-teal-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-teal-300 dark:focus:ring-teal-800 font-medium rounded-lg text-sm px-5 h-12 text-center mb-2 sm:mb-0"
                              type="button"
                            >
                              <p className="mx-1">More Details</p>
                            </button>
                          </Link>
                        </div>
                      </div>
                    ))}
                </div>
              ) : (
                <Link to={`/agent/${agent.user._id}`} key={agent.user._id}>
                  <button
                    className="text-white w-60 bg-gradient-to-r flex flex-row items-center justify-center from-teal-400 via-teal-500 to-teal-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-teal-300 dark:focus:ring-teal-800 font-medium rounded-lg text-sm px-5 h-12 text-center mb-2 sm:mb-0"
                    type="button"
                  >
                    <p className="mx-1">More about {agent.user.firstName} </p>
                  </button>
                </Link>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useAuthStore } from "../store/store";
import AvailableTasks from "../accountUser/AvailableTasks";
import Tasks from "../accountUser/Tasks";

export default function Navbar() {
  const navigate = useNavigate();

  const [selectedTab, setSelectedTab] = useState("dashboard");
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);
  const hasWarnedRef = useRef(false);

  const {
    user,
    setIsLoggedIn,
    userLogOutUrl,
    setUser,
    clearAuthToken,
    isLoggedIn,
  } = useAuthStore();
  const [showConfirmation, setShowConfirmation] = useState(false);

  useEffect(() => {
    if (!isLoggedIn && !hasWarnedRef.current) {
      navigate("/signIn");
      toast.warn("Please login to access your dashboard.", {
        position: "top-left",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      hasWarnedRef.current = true;
    }
  }, [isLoggedIn, navigate]);

  const logout = async () => {
    try {
      const authToken = localStorage.getItem("authToken");

      const res = await axios.get(userLogOutUrl, {
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });

      if (res.status === 200) {
        clearAuthToken();

        setIsLoggedIn(false);

        navigate("/signIn");

        toast.success("Successfully logged out", {
          position: "top-left",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    } catch (err) {
      console.error("Error logging out:", err);
      toast.error("Failed to log out. Please try again later.", {
        position: "top-left",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };

  const handleLogout = () => setShowConfirmation(true);

  const confirmLogout = () => {
    logout();
    // setShowConfirmation(false);
    // toast.success("Successfully logged out", {
    //   position: "top-left",
    //   autoClose: 2000,
    //   hideProgressBar: false,
    //   closeOnClick: true,
    //   pauseOnHover: true,
    //   draggable: true,
    //   progress: undefined,
    //   theme: "dark",
    // });
  };

  const cancelLogout = () => setShowConfirmation(false);

  return (
    <div className="flex flex-col lg:flex-row min-h-screen">
      {/* Sidebar */}
      <div className="w-full lg:w-64 bg-gray-900">
        <nav className={`p-2 ${isMenuOpen ? "block" : "hidden"} lg:block`}>
          <ul>
          <li
              className={`cursor-pointer text-secondary p-2 ${
                selectedTab === "dashboard" ? "bg-secondaryp" : ""
              }`}
              onClick={() => setSelectedTab("dashboard")}
            >
              Dashboard
            </li>
            <li
              className={`cursor-pointer text-secondary p-2 ${
                selectedTab === "TasksCompleted" ? "bg-secondaryp" : ""
              }`}
              onClick={() => setSelectedTab("TasksCompleted")}
            >
              Tasks Completed
            </li>

            <li
              className={`cursor-pointer text-secondary p-2 ${
                selectedTab === "AvailableTasks" ? "bg-secondaryp" : ""
              }`}
              onClick={() => setSelectedTab("AvailableTasks")}
            >
              Available Tasks
            </li>
          </ul>
          
          <button
            onClick={handleLogout}
            className="bg-red-500 ml-2 my-5 text-white px-6 py-2 rounded-sm"
          >
            Logout
          </button>
          <div className="flex justify-center items-center mt-auto py-4">
            <h1 className="text-sm text-center text-gray-800">Welcome👋 {user?.firstName} {user?.lastName}</h1>
          </div>

        </nav>
      </div>

      {/* Main Content */}
      
    </div>
  );
}

import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useAuthStore } from "../store/store";
import { toast } from "react-toastify";

export default function AvailableTaskDetails() {
  const { assignTask, csvDataID, user, undoAssignedTask } = useAuthStore();

  const { id } = useParams();
  const [selectedRow, setSelectedRow] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [taskTitle, setTaskTitle] = useState("");
  const [taskDescription, setTaskDescription] = useState("");
  const [assignError, setAssignError] = useState("");
  const [taskResponse, setTaskResponse] = useState(null);
  const [taskAssigned, setTaskAssigned] = useState(false);
  const [showInputFields, setShowInputFields] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get(`${csvDataID}/${id}`);
        setSelectedRow(response.data);
        setTaskResponse(response.data);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching row data:", error);
      }
    };

    fetchData();
  }, [id, csvDataID]);

  useEffect(() => {
    if (taskResponse && taskResponse.task && taskResponse.task._id) {
      setTaskAssigned(true);
      setShowInputFields(false); // Hide input fields once task is assigned
    } else {
      setTaskAssigned(false);
      setShowInputFields(true); // Show input fields if no task assigned
    }
  }, [taskResponse]);

  const assignTaskFunc = async () => {
    try {
      if (!taskTitle || !taskDescription || !user.email || !user.location) {
        throw new Error(
          "Please provide title, description, userEmail, and userLocation!"
        );
      }

      const taskDetails = {
        VRM: selectedRow.VRM,
        CaseReference: selectedRow.CaseReference,
        PCNNumber: selectedRow.PCNNumber,
        DebtorName: selectedRow.DebtorName,
        Debtor_Address: selectedRow.Debtor_Address,
        Debtor_Post_Code: selectedRow.Debtor_Post_Code,
        ContraventionDate: selectedRow.ContraventionDate,
        Scheme: selectedRow.Scheme,
        CaseReferenceRepeat: selectedRow.CaseReferenceRepeat,
        TotalClientBalance: selectedRow.TotalClientBalance,
        TotalFeeBalance: selectedRow.TotalFeeBalance,
        TotalBalance: selectedRow.TotalBalance,
        ContactNumber1: selectedRow.ContactNumber1,
        ContactNumber2: selectedRow.ContactNumber2,
        ContactNumber3: selectedRow.ContactNumber3,
        ContactNumber4: selectedRow.ContactNumber4,
        current_status: selectedRow.current_status,
        Routed: selectedRow.Routed,
      };

      const response = await axios.post(assignTask, {
        title: taskTitle,
        description: taskDescription,
        taskDetails: taskDetails,
        userEmail: user.email,
        userLocation: user.location,
      });

      if (!response) {
        throw new Error("No response received from the server.");
      }

      localStorage.setItem("taskResponse", JSON.stringify(response.data));
      setTaskResponse(response.data);

      toast.success("Task successfully assigned", {
        position: "top-right",
        autoClose: 3001,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        style: {
          borderRadius: "10px",
        },
      });

      setTaskTitle("");
      setTaskDescription("");
      setAssignError("");
      setTaskAssigned(true); // Mark task as assigned
      setShowInputFields(false); // Hide input fields after successful assignment

    } catch (error) {
      console.error("Error assigning task:", error.message);
      setAssignError(error.message);
    }
  };

  const unassignTaskFunc = async () => {
    try {
      const storedTaskResponse = localStorage.getItem("taskResponse");
      const taskResponse = storedTaskResponse ? JSON.parse(storedTaskResponse) : null;

      if (!taskResponse || !taskResponse.task || !taskResponse.task._id) {
        throw new Error("No task ID available to unassign.");
      }

      const response = await axios.post(undoAssignedTask, {
        taskId: taskResponse.task._id,
      });

      if (!response) {
        throw new Error("No response received from the server.");
      }

      toast.success("Task successfully unassigned", {
        position: "top-right",
        autoClose: 3001,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        style: {
          borderRadius: "10px",
        },
      });

      localStorage.removeItem("taskResponse");
      setTaskResponse(null);

      setTaskTitle("");
      setTaskDescription("");
      setAssignError("");
      setTaskAssigned(false); // Mark task as unassigned
      setShowInputFields(true); // Show input fields after unassignment

    } catch (error) {
      console.error("Error unassigning task:", error.message);
      setAssignError(error.message);
    }
  };

  return (
    <div className="bg-gray-800 p-5">
      <p className="text-sm text-white my-5 text-center">
        Assign or unassign task to yourself
      </p>

      <div className="flex flex-col items-center justify-center min-h-screen">
        {isLoading ? (
          <div className="flex justify-center items-center min-h-screen bg-gray-100 w-full rounded-md">
            <svg
              className="w-16 h-16 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6267 93.9676 39.0409Z"
                fill="currentColor"
              />
            </svg>
            <span className="sr-only">Loading...</span>
          </div>
        ) : (
          <div className="w-full">
            {showInputFields && (
              <div className="mt-6 w-full flex flex-col justify-center">
                <section className="flex flex-row justify-between items-center mb-4">
                  <div className="relative mb-3">
                    <label
                      className="block text-gray-700 text-xs mb-1"
                      htmlFor="task-title"
                    >
                      Task Title
                    </label>
                    <textarea
                      id="task-title"
                      className="border border-gray-300 px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring-2 focus:ring-blue-500 w-full"
                      placeholder="Enter Task Title"
                      value={taskTitle}
                      onChange={(e) => setTaskTitle(e.target.value)}
                    ></textarea>
                  </div>
                  <div className="relative mb-3">
                    <label
                      className="block text-gray-700 text-xs mb-1"
                      htmlFor="task-description"
                    >
                      Task Description
                    </label>
                    <textarea
                      id="task-description"
                      className="border border-gray-300 px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring-2 focus:ring-blue-500 w-full"
                      placeholder="Enter Task Description"
                      value={taskDescription}
                      onChange={(e) => setTaskDescription(e.target.value)}
                    ></textarea>
                  </div>
                  <div className="relative mb-3">
                    {!taskAssigned ? (
                      <button
                        type="button"
                        className="text-white bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mb-2"
                        onClick={assignTaskFunc}
                      >
                        Assign Task
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="text-white ml-4 bg-gradient-to-br from-red-600 to-pink-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mt-2 mb-2"
                        onClick={unassignTaskFunc}
                      >
                        Unassign Task
                      </button>
                    )}
                  </div>
                </section>
              </div>
            )}

            <div className="flex flex-col md:flex-row justify-between items-center my-6 bg-gray-800 p-3 rounded-md">
              <h2 className="text-lg text-orange-400 font-light mb-4 md:mb-0">
                Complete details
              </h2>
            </div>

            <div className="bg-white divide-y divide-gray-200 rounded-lg p-5 w-full">
  {/* Display Assigned Task Details */}
            {taskResponse && taskResponse.task && (
              <div className="bg-white divide-y divide-gray-200 rounded-lg p-5 mt-4">
                  <div className="relative mb-3">
                  {!taskAssigned ? (
                    <button
                      type="button"
                      className="text-white bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mb-2"
                      onClick={assignTaskFunc}
                    >
                      Assign Task
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="text-white ml-4 bg-gradient-to-br from-red-600 to-pink-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mt-2 mb-2"
                      onClick={unassignTaskFunc}
                    >
                      Unassign Task
                    </button>
                    
                  )}
                </div>
                <h2 className="text-lg font-semibold mb-2">
                  Assigned Task Details
                </h2>
                <p>
                  <strong>Title:</strong> {taskResponse.task.title}
                </p>
                <p>
                  <strong>Description:</strong> {taskResponse.task.description}
                </p>
              </div>
          
            )}
              <table className="min-w-full">
                <tbody>
                  {Object.entries(selectedRow)
                    .filter(([key]) => key !== "_id" && key !== "title")
                    .map(([key, value], index) => (
                      <tr key={index} className="w-full">
                        <td className="w-full md:w-1/2 pl-3 py-4 whitespace-no-wrap font-semibold border border-gray-400 bg-blue-100">
                          {key}
                        </td>
                        <td className="w-full md:w-1/2 pl-3 pr-1 py-4 whitespace-no-wrap border border-gray-400">
                          {value}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useAuthStore } from "../store/store";
import CommentList from "../components/CommentList";
import CommentForm from "../components/CommentForm";
import VehicleDetails from "../components/VehicleDetails";
import Modal from "../components/Modal";

export default function TaskDetails() {
  const { googleMapDirectionUrl, tasks, updateTaskStatus, user, VehicleDetailsUrl, checkClamped, checkClampedAlert } = useAuthStore();
  const { taskId } = useParams();
  const [taskDetails, setTaskDetails] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [directions, setDirections] = useState([]);
  const [directionsUrl, setDirectionsUrl] = useState("");
  const [showIframe, setShowIframe] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [currentLocation, setCurrentLocation] = useState(null);
  const [status, setStatus] = useState("");
  const [vehicleDetails, setVehicleDetails] = useState(null);
  const [showVModal, setShowVModal] = useState(false);
  const [notification, setNotification] = useState(null);  
  const [isNotificationVisible, setIsNotificationVisible] = useState(true);
  const [lastUpdated, setLastUpdated] = useState("");

  const fetchTaskDetails = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(`${tasks}/${taskId}`);
      setTaskDetails(response.data);
      setStatus(response.data.status);
      setIsLoading(false);
  
      if (response.data.lastStatusChange) {
        const updatedAt = new Date(response.data.lastStatusChange);
        const formattedDate = updatedAt.toLocaleDateString("en-GB", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        });
        const formattedTime = updatedAt.toLocaleTimeString("en-GB", {
          hour: "2-digit",
          minute: "2-digit",
        });
        setLastUpdated(`${formattedDate} at ${formattedTime}`);
      }
  
      if (response.data.taskDetails?.VRM) {
        fetchVehicleDetails(response.data.taskDetails.VRM);
      }
    } catch (error) {
      console.error("Error fetching task details:", error);
      setIsLoading(false);
    }
  };
  
  

  useEffect(() => {
    fetchTaskDetails();
  }, [taskId]);

  const handleCommentAdded = () => {
    fetchTaskDetails();
  };

  useEffect(() => {
    if (taskDetails.taskDetails && taskDetails.taskDetails.Debtor_Post_Code) {
      fetchDirections();
    }
  }, [taskDetails]);

  const fetchDirections = () => {
    navigator.geolocation.getCurrentPosition(
      async (position) => {
        const location = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        setCurrentLocation(location);
        const destination = taskDetails.taskDetails.Debtor_Post_Code;

        try {
          const response = await axios.get(googleMapDirectionUrl, {
            params: {
              origin: `${location.lat},${location.lng}`,
              destination: destination,
              key: "AIzaSyA00p2zFlRDvkDA9u8mPdSmbGMxuBNcP-U",
            },
          });
          console.log(response.data); 
          if (response.data.routes && response.data.routes.length > 0) {
            const steps = response.data.routes[0].legs[0].steps.map((step) => ({
              instructions: step.html_instructions,
              maneuver: step.maneuver,
            }));
            setDirections(steps);
            const embedUrl = `https://www.google.com/maps/embed/v1/directions?key=AIzaSyA00p2zFlRDvkDA9u8mPdSmbGMxuBNcP-U&origin=${location.lat},${location.lng}&destination=${destination}&mode=driving`;
            setDirectionsUrl(embedUrl);
            setShowIframe(true);
          } else {
            console.error("No routes found");
          }
        } catch (error) {
          console.error("Error fetching directions:", error);
        }
        
      },
      (error) => {
        console.error("Geolocation failed: " + error.message);
      },
      { enableHighAccuracy: true }
    );
  };

  const openFullscreen = () => {
    const iframe = document.getElementById("directions-iframe");
    if (iframe) {
      if (iframe.requestFullscreen) {
        iframe.requestFullscreen();
      } else if (iframe.mozRequestFullScreen) {
        iframe.mozRequestFullScreen();
      } else if (iframe.webkitRequestFullscreen) {
        iframe.webkitRequestFullscreen();
      } else if (iframe.msRequestFullscreen) {
        iframe.msRequestFullscreen();
      }
    }
  };

  const openMapApp = (app) => {
    if (!currentLocation) return;
    const { lat, lng } = currentLocation;
    const destination = taskDetails.taskDetails.Debtor_Post_Code;
    let url = "";

    switch (app) {
      case "google":
        url = `https://www.google.com/maps/dir/?api=1&origin=${lat},${lng}&destination=${destination}&travelmode=driving`;
        break;
      case "apple":
        url = `http://maps.apple.com/?saddr=${lat},${lng}&daddr=${destination}&dirflg=d`;
        break;
      case "waze":
        url = `https://waze.com/ul?ll=${destination}&navigate=yes`;
        break;
      default:
        break;
    }

    window.open(url, "_blank");
    setShowModal(false);
  };

  const handleStatusUpdate = async () => {
    try {
      await axios.patch(`${updateTaskStatus}/${taskId}/status`, { status });
      alert("Task status updated successfully");
      fetchTaskDetails();
    } catch (error) {
      console.error("Error updating task status:", error);
      alert("Failed to update task status");
    }
  };

  const fetchVehicleDetails = async (regNo) => {
    try {
      const response = await axios.post(VehicleDetailsUrl, { registrationNumber: regNo }, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      
      setVehicleDetails(response.data);
      console.log(response.data);
    } catch (error) {

      console.error("Error fetching vehicle details:", error);
    }
  };

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        
        const [tasksResponse, alertsResponse] = await Promise.all([
          axios.get(checkClamped),
          axios.get(checkClampedAlert),
        ]);
  
        const taskNotifications = tasksResponse.data.notifications || [];
        const alertNotifications = alertsResponse.data.notifications || [];
  
        const allNotifications = [...taskNotifications, ...alertNotifications];
  
        allNotifications.forEach(notification => {
          if (notification.userId === user._id && notification.taskId === taskId) {
            setNotification(notification.message);
          }
        });
  
      } catch (error) {
        console.error("Error fetching notifications:", error);
      }
    };
  
    fetchNotifications();
  }, [taskId, user._id]);
  

  console.log("Note:", notification)

  return (
    <div className="p-5 flex items-center justify-center bg-gray-800 py-20">
      {isLoading ? (
        <div role="status" className="flex justify-center items-center"></div>
      ) : (
        
        <div className="w-full max-w-4xl">
  <div className="mb-6">
  <h2 className="text-3xl font-medium text-gray-600 mb-4">
    {taskDetails.title}
  </h2>
  <p className="text-gray-500">{taskDetails.description}</p>
  {lastUpdated && (
    <p className="text-gray-400 text-sm mt-2">
      Last updated: {lastUpdated}
    </p>
  )}
</div>


{notification && isNotificationVisible && (
  <div className="fixed top-5 right-5 bg-red-500 text-white p-4 rounded shadow-lg flex items-center justify-between z-50">
    <span>{notification}</span>
    <button
      className="ml-4 bg-white text-red-500 rounded-full w-6 h-6 flex items-center justify-center"
      onClick={() => setIsNotificationVisible(false)}
    >
      &times;
    </button>
  </div>
)}


          <div className="flex flex-col sm:flex-row sm:space-x-4 mt-4 w-full">
            <div className="mt-6 w-full sm:mt-0">
              <label
                className="block text-gray-400 text-xs mb-2"
                htmlFor="status"
              >
                Please update task status
              </label>
              <div className="relative">
                <select
                  id="status"
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                  className="mb-4 block appearance-none w-full bg-gray-800 border border-gray-600 text-white py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-gray-700 focus:border-blue-500 transition duration-300 ease-in-out transform hover:scale-105"
                >
                  <option value="clamped">Clamped</option>
                  <option value="cut">Cut</option>
                  <option value="pif">Pif</option>
                  {/* <option value="completed">Completed</option> */}
                  <option value="arrangement">Arrangement</option>
                </select>
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-500">
                  <svg
                    className="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M7 10l5 5 5-5H7z" />
                  </svg>
                </div>
              </div>
            </div>

            <button
              onClick={handleStatusUpdate}
              type="button"
              className="text-white w-full bg-gradient-to-r from-teal-400 via-teal-500 to-teal-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-teal-300 dark:focus:ring-teal-800  font-medium rounded-lg text-sm px-5 h-20 text-center sm:ml-4 mb-2 sm:mb-0"
            >
              <p className="font-medium text-white">Update status</p>
            </button>
          </div>

          {showIframe && directionsUrl && (
            <div className="my-6">
              <iframe
                id="directions-iframe"
                src={directionsUrl}
                className="w-full h-64 sm:h-96 border-0"
                allowFullScreen
                loading="lazy"
                title="Google Maps Directions"
              ></iframe>
            </div>
          )}

          <button
            onClick={openFullscreen}
            type="button"
            className="text-white w-full transition bg-gradient-to-br from-green-400 to-blue-600 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-green-200 dark:focus:ring-green-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mb-2 sm:mb-0"
          >
            Open Full Screen
          </button>

          <button
            onClick={() => setShowVModal(true)}
            type="button"
            className="text-white w-full bg-gradient-to-r from-teal-400 via-teal-500 to-teal-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-teal-300 dark:focus:ring-teal-800 font-medium rounded-lg text-sm px-5 h-20 text-center mt-4"
          >
            View Vehicle Details
          </button>

             <Modal showVModal={showVModal} closeModal={() => setShowVModal(false)}>
            <VehicleDetails vehicleDetails={vehicleDetails} />
          </Modal>

          <CommentForm taskId={taskId} onCommentAdded={handleCommentAdded} />
          <CommentList taskId={taskId} userId={user._id}   isAdmin={true} onCommentAdded={handleCommentAdded} comments={taskDetails.comments || [] } />

          <div className="mt-6 w-full flex flex-col justify-center">
            <section className="flex flex-row justify-between items-center">
              <h3 className="text-lg text-blue-300 font-bold mb-5">
                Directions
              </h3>
              <button
                type="button"
                className="text-white bg-gradient-to-br from-pink-500 to-orange-400 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-pink-200 dark:focus:ring-pink-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
                onClick={() => setShowModal(true)}
              >
                Start route
              </button>
            </section>
            <div className="max-h-96 overflow-y-auto bg-gray-700 p-4 rounded">
              <ol className="list-decimal list-inside">
                {directions.map((step, index) => (
                  <li
                    className="bg-orange-100 text-gray-800 text-lg p-4 text-left my-5"
                    key={index}
                    dangerouslySetInnerHTML={{ __html: `${step.instructions}` }}
                  />
                ))}
              </ol>
            </div>
          </div>

          <div className="overflow-x-auto mt-6">
            <table className="min-w-full border-gray-400 rounded-lg">
              <thead className="bg-orange-100">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Key
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Value
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {Object.entries(taskDetails.taskDetails).map(
                  ([key, value], index) => (
                    <tr key={index}>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-semibold text-gray-700 border-r border-gray-400">
                        {key}
                      </td>
                      <td className="px-6 py-4 bg-blue-50 whitespace-nowrap text-sm text-gray-700 border-l border-gray-400">
                        {value}
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
          </div>
        </div>
      )}

      {showModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
          <div className="bg-white w-[300px] flex flex-col items-center justify-between p-6 rounded-lg">
            <h2 className="text-md font-medium w-full border-b border-gray-500 border-b-1 pb-4 mb-5 text-gray-800 ">
              Select navigation app
            </h2>
            <button
              className="bg-blue-500 w-[200px] hover:bg-blue-700 text-white text-lg py-5 rounded-lg mb-2"
              onClick={() => openMapApp("google")}
            >
              Google Maps
            </button>
            <button
              className="bg-gray-500 w-[200px] hover:bg-gray-700 text-white text-lg py-5 rounded-lg mb-2"
              onClick={() => openMapApp("apple")}
            >
              Apple Maps
            </button>
            <button
              className="bg-purple-500 w-[200px] hover:bg-purple-700 text-white text-lg py-5 rounded-lg mb-2"
              onClick={() => openMapApp("waze")}
            >
              Waze Maps
            </button>
            <button
              className="text-red-500 w-full border-t border-gray-500 border-t-1 pt-4 mt-5 py-5 px-4 rounded"
              onClick={() => setShowModal(false)}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
import React from "react";

function Modal({ showVModal, closeModal, children }) {
  if (!showVModal) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50 p-4 sm:p-6">
      <div
        className="bg-white w-full max-w-lg sm:max-w-2xl lg:max-w-4xl p-4 sm:p-6 rounded-lg overflow-y-auto"
        style={{
          maxHeight: "80vh",
          scrollbarWidth: "none", 
          msOverflowStyle: "none", 
        }}
      >
        <button
          className="text-red-500 w-full text-right mb-4"
          onClick={closeModal}
        >
          Close
        </button>
        {children}
      </div>
    </div>
  );
}

export default Modal;
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useAuthStore } from "../store/store";
import { toast } from "react-toastify";
import Navbar from "../components/Navbar";

export default function TaskDashboard() {
  const apiKey = process.env.REACT_APP_GoogleMapApiKey;
  const { googleMapDirectionUrl, tasks, updateTaskStatus } = useAuthStore();
  const { taskId } = useParams();
  const [taskDetails, setTaskDetails] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [directions, setDirections] = useState([]);
  const [directionsUrl, setDirectionsUrl] = useState("");
  const [showIframe, setShowIframe] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [currentLocation, setCurrentLocation] = useState(null);
  const [status, setStatus] = useState("");

  useEffect(() => {
    const fetchTaskDetails = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get(`${tasks}/${taskId}`);
        setTaskDetails(response.data);
        setStatus(response.data.status);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching task details:", error);
        setIsLoading(false);
      }
    };

    fetchTaskDetails();
  }, [taskId]);

  useEffect(() => {
    if (taskDetails.taskDetails && taskDetails.taskDetails.Debtor_Post_Code) {
      fetchDirections();
    }
  }, [taskDetails]);

  const fetchDirections = () => {
    navigator.geolocation.getCurrentPosition(
      async (position) => {
        const location = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        setCurrentLocation(location);
        const destination = taskDetails.taskDetails.Debtor_Post_Code;

        try {
          const response = await axios.get(googleMapDirectionUrl, {
            params: {
              origin: `${location.lat},${location.lng}`,
              destination: destination,
              key: apiKey,
            },
          });

          const steps = response.data.routes[0].legs[0].steps.map((step) => ({
            instructions: step.html_instructions,
            maneuver: step.maneuver,
          }));

          setDirections(steps);

          const embedUrl = `https://www.google.com/maps/embed/v1/directions?key=${apiKey}&origin=${location.lat},${location.lng}&destination=${destination}&mode=driving`;
          setDirectionsUrl(embedUrl);
          setShowIframe(true);
        } catch (error) {
          console.error("Error fetching directions:", error);
        }
      },
      (error) => {
        console.error("Geolocation failed: " + error.message);
      },
      { enableHighAccuracy: true }
    );
  };

  const openFullscreen = () => {
    const iframe = document.getElementById("directions-iframe");
    if (iframe) {
      if (iframe.requestFullscreen) {
        iframe.requestFullscreen();
      } else if (iframe.mozRequestFullScreen) {
        iframe.mozRequestFullScreen();
      } else if (iframe.webkitRequestFullscreen) {
        iframe.webkitRequestFullscreen();
      } else if (iframe.msRequestFullscreen) {
        iframe.msRequestFullscreen();
      }
    }
  };

  const openMapApp = (app) => {
    if (!currentLocation) return;
    const { lat, lng } = currentLocation;
    const destination = taskDetails.taskDetails.Debtor_Post_Code;
    let url = "";

    switch (app) {
      case "google":
        url = `https://www.google.com/maps/dir/?api=1&origin=${lat},${lng}&destination=${destination}&travelmode=driving`;
        break;
      case "apple":
        url = `http://maps.apple.com/?saddr=${lat},${lng}&daddr=${destination}&dirflg=d`;
        break;
      case "waze":
        url = `https://waze.com/ul?ll=${destination}&navigate=yes`;
        break;
      default:
        break;
    }

    window.open(url, "_blank");
    setShowModal(false);
  };

  const handleStatusUpdate = async () => {
    try {
      await axios.patch(
        // `http://localhost:7000/api/v1/user/tasks/${taskId}/status`,
        `${updateTaskStatus}/${taskId}/status`,
        { status }
      );
      toast.success("Task status updated successfully", {
        position: "top-center",
        autoClose: 3001,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        style: {
          borderRadius: "10px",
        },
      });
    } catch (error) {
      console.error("Error updating task status:", error);
      alert("Failed to update task status");
    }
  };

  return (
    <div className="flex flex-col min-h-screen">

      <div className="p-5 flex items-center justify-center py-10">
        {isLoading ? (
          <div role="status" className="flex justify-center items-center"></div>
        ) : (
          <div className="w-full">
            <div className="mb-6">
              <h2 className="text-3xl font-medium text-gray-600 ">
                {taskDetails.title}
              </h2>
              <p className="text-gray-500">{taskDetails.description}</p>
            </div>

            <div className="flex flex-col sm:flex-row sm:items-start sm:space-x-4 mt-4 w-full">
  <div>

      <select
        id="status"
        value={status}
        onChange={(e) => setStatus(e.target.value)}
        className="block appearance-none  border border-gray-600 text-grey py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white-700 focus:border-blue-500 transition duration-300 ease-in-out transform hover:scale-105"
      >
        <option value="clamped">Clamped</option>
        <option value="cut">Cut</option>
        <option value="pif">Pif</option>
        <option value="completed">Completed</option>
        <option value="arrangement">Arrangement</option>
      </select>
      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-500">
        <svg
          className="fill-current h-4 w-4"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
        >
          <path d="M7 10l5 5 5-5H7z" />
        </svg>
      </div>
    
  </div>

  <button
    onClick={handleStatusUpdate}
    type="button"
    className="text-white px-4 pr-8 rounded bg-gradient-to-r from-teal-400 via-teal-500 to-teal-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-teal-300 dark:focus:ring-teal-800 font-medium rounded-lg text-sm px-5 py-3 sm:py-2 text-center"
  >
    <h2>Update</h2>
  </button>
</div>

            {showIframe && directionsUrl && (
              <div className="my-6">
                <iframe
                  id="directions-iframe"
                  src={directionsUrl}
                  className="w-full h-64 sm:h-96 border-0"
                  allowFullScreen
                  loading="lazy"
                  title="Google Maps Directions"
                ></iframe>
              </div>
            )}



<div className="mt-6 w-full flex flex-col justify-center">
  <section className="flex flex-row justify-between items-center mb-4">
  <p className="text-gray-500">Directions</p>

    <h3 className="text-lg text-blue-300 font-bold"></h3>
    <div className="flex space-x-2">
      <button
        onClick={openFullscreen}
        type="button"
        className="text-white transition bg-gradient-to-br from-green-400 to-blue-600 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-green-200 dark:focus:ring-green-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
      >
        Open Full Screen
      </button>
      <button
        onClick={() => setShowModal(true)}
        type="button"
        className="text-white bg-gradient-to-br from-pink-500 to-orange-400 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-pink-200 dark:focus:ring-pink-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
      >
        Start route
      </button>
    </div>
  </section>
  <div className="max-h-96 overflow-y-auto bg-orange-100 p-4 rounded">
    <ol className="list-decimal list-inside">
      {directions.map((step, index) => (
        <li
          className="bg-blue-100 text-gray-800 text-lg p-4 text-left my-5"
          key={index}
          dangerouslySetInnerHTML={{ __html: `${step.instructions}` }}
        />
      ))}
    </ol>
  </div>
</div>

            <div className="overflow-x-auto mt-6">
              <table className="min-w-full border-gray-400 rounded-lg">
                <thead className="bg-orange-100">
                  <tr>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Key
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Value
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {Object.entries(taskDetails.taskDetails).map(
                    ([key, value], index) => (
                      <tr key={index}>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-semibold text-gray-700 border-r border-gray-400">
                          {key}
                        </td>
                        <td className="px-6 py-4 bg-blue-50 whitespace-nowrap text-sm text-gray-700 border-l border-gray-400">
                          {value}
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>

      {showModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
          <div className="bg-white w-[300px] flex flex-col items-center justify-between p-6 rounded-lg">
            <h2 className="text-md font-medium w-full border-b border-gray-500 border-b-1 pb-4 mb-5 text-gray-800 ">
              Select navigation app
            </h2>
            <button
              className="bg-blue-500 w-[200px] hover:bg-blue-700 text-white text-lg py-5 rounded-lg mb-2"
              onClick={() => openMapApp("google")}
            >
              Google Maps
            </button>
            <button
              className="bg-gray-500 w-[200px] hover:bg-gray-700 text-white text-lg py-5 rounded-lg mb-2"
              onClick={() => openMapApp("apple")}
            >
              Apple Maps
            </button>
            <button
              className="bg-purple-500 w-[200px] hover:bg-purple-700 text-white text-lg py-5 rounded-lg mb-2"
              onClick={() => openMapApp("waze")}
            >
              Waze Maps
            </button>
            <button
              className="text-red-500 w-full border-t border-gray-500 border-t-1 pt-4 mt-5 py-5 px-4 rounded"
              onClick={() => setShowModal(false)}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

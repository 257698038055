import React, { useEffect, useState } from "react";
import { useAuthStore } from "../store/store";
import axios from "axios";
import { Link } from "react-router-dom";
import { IoIosArrowRoundForward } from "react-icons/io";

export default function TasksCompleted() {
  const { csvData, userEmail, user, userTasks } = useAuthStore();
  const apiUrl = csvData;
  const [data, setData] = useState([]);
  const [assignedTasks, setAssignedTasks] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    fetch(apiUrl)
      .then((response) => response.json())
      .then((res) => {
        setData(res.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log("Error fetching csv data", error);
        setIsLoading(false);
      });
  }, [apiUrl]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get(
          `${userTasks}/${user.email}`
        );
        const sortedTasks = response.data.tasks.sort((a, b) =>
          b._id.localeCompare(a._id)
        );
        setAssignedTasks(sortedTasks);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, [userEmail]);

  // Filter and sort completed tasks
  const completedTasks = assignedTasks
    .filter((task) => task.taskStatus === "completed")
    .sort((a, b) => b._id.localeCompare(a._id));

  return (
    <div className="flex flex-col justify-center items-center min-h-screen bg-gray-800 p-5">
      {isLoading ? (
        <div role="status" className="flex mt-60 justify-center items-center">
          <svg
            aria-hidden="true"
            className="w-16 h-16 text-orange-500 animate-spin fill-orange-300"
            viewBox="0 0 100 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
              fill="currentColor"
            />
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill="currentFill"
            />
          </svg>
          <span className="sr-only">Loading...</span>
        </div>
      ) : (
        <div className="container mx-auto p-4">
          <h3 className="text-2xl font-semibold text-gray-800 mb-8 text-center">
            Tasks You Have Completed
          </h3>
          <div className="grid gap-8 sm:grid-cols-1 md:grid-cols-2 ">
            {completedTasks.map((task) => (
              <div
                key={task._id}
                className="relative flex flex-col my-2 bg-clip-border rounded-xl bg-white text-gray-700 shadow-md lg:h-max lg:scale-105 z-10 translate-y-0"
              >
                <div className="p-6 w-full font-light text-left">
                  <p className="block antialiased font-sans leading-relaxed text-sm font-normal text-inherit mb-4">
                    Title <p className="font-light text-xs">{task.title}</p>
                  </p>
                  <p className="block font-medium antialiased font-sans leading-relaxed text-sm text-inherit mb-4">
                    Description: <p className="font-light text-xs">{task.description}</p>
                  </p>
                  <div className="text-sm tex-left text-gray-500 mb-5">
                    <p className="block font-medium antialiased font-sans leading-relaxed text-sm text-inherit mb-4">
                      Assigned: <p className="font-light text-xs">{user?.email}</p>
                    </p>
                    <p className="block font-medium antialiased font-sans leading-relaxed text-sm text-inherit mb-4">
                      Status <p className="font-light text-xs">{task.taskStatus}</p>
                    </p>
                  </div>
                  <Link to={`/completed-task-details/${task._id}`} key={task._id}>
                    <button
                      className="text-white w-60 bg-gradient-to-r flex flex-row items-center justify-center from-teal-400 via-teal-500 to-teal-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-teal-300 dark:focus:ring-teal-800 font-medium rounded-lg text-sm px-5 h-12 text-center mb-2 sm:mb-0"
                      type="button"
                    >
                      <p className="mx-1">View Details</p>
                      <IoIosArrowRoundForward color="#ffffff" size={18} />
                    </button>
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

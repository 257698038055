import React, { useEffect, useState } from "react";
import { useAuthStore } from "../store/store";
import CompleteDetails from "./CompleteDetails";

export default function DisplayCSV() {
  
  const { unAssignedCSVData } = useAuthStore();
  const apiUrl = unAssignedCSVData;
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedRow, setSelectedRow] = useState(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    fetch(apiUrl)
      .then((response) => response.json())
      .then((res) => {
        const reversedData = res.data.reverse(); // Reverse the array to show newest entries at the top
        setData(reversedData);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log("Error fetching csv data", error);
      });
  }, [apiUrl]);

  const handleRowClick = (index) => {
    const selectedRowData = data[index];
    const completedDetailsUrl = `/completed-details/${selectedRowData._id}`;
    window.location.href = completedDetailsUrl;
  };

  const closeModal = () => {
    setSelectedRow(null);
    setIsDropdownOpen(false);
  };

  const handleAssignTask = () => {
    console.log("Task assigned to location:", selectedLocation);
    setSelectedLocation(null);
  };

  const handleLocationSelect = (location) => {
    setSelectedLocation(location);
    setIsDropdownOpen(false);
  };

  const groupedData = data.reduce((acc, row) => {
    const title = row.title;
    if (!acc[title]) {
      acc[title] = [];
    }
    acc[title].push(row);
    return acc;
  }, {});

  return (
    <div className="container mx-auto">
      {isLoading ? (
        <div role="status" className="flex mt-60 justify-center items-center">
          <svg
            aria-hidden="true"
            className="w-16 h-16 text-gray-200 animate-spin fill-blue-600"
            viewBox="0 0 100 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M100 50.591C100 78.205 77.614 100.591 50 100.591 22.386 100.591 0 78.205 0 50.591 0 22.977 22.386 0.591 50 0.591 77.614 0.591 100 22.977 100 50.591ZM9.081 50.591C9.081 73.189 27.401 91.509 50 91.509 72.599 91.509 90.919 73.189 90.919 50.591 90.919 27.992 72.599 9.672 50 9.672 27.401 9.672 9.081 27.992 9.081 50.591Z"
              fill="currentColor"
            />
            <path
              d="M93.968 39.041C96.393 38.404 97.862 35.912 97.008 33.554 95.293 28.823 92.871 24.369 89.817 20.348 85.845 15.119 80.883 10.724 75.212 7.413 69.542 4.102 63.275 1.94 56.77 1.051 51.767 0.368 46.698 0.447 41.735 1.279 39.261 1.693 37.813 4.198 38.45 6.623 39.087 9.049 41.569 10.472 44.051 10.107 47.851 9.549 51.719 9.527 55.54 10.049 60.864 10.777 65.993 12.546 70.633 15.255 75.274 17.965 79.335 21.562 82.585 25.841 84.918 28.912 86.8 32.291 88.181 35.876 89.083 38.216 91.542 39.678 93.968 39.041Z"
              fill="currentFill"
            />
          </svg>
          <span className="sr-only">Loading...</span>
        </div>
      ) : (
        <div>
          {Object.keys(groupedData).map((title, titleIndex) => (
            <div key={titleIndex} className="mb-8">
              <h2 className="text-2xl font-semibold mb-4">{title}</h2>
              <div className="border-1.5 border-gray-800 mb-4 p-4 rounded-md">
                {groupedData[title].map((file, fileIndex) => (
                  <table
                    key={fileIndex}
                    className="min-w-full divide-y divide-gray-200 mb-4"
                  >
                    <thead>
                      <tr className="bg-blue-100">
                        <th className="px-6 py-6 text-left text-xs font-medium text-gray uppercase tracking-wider">
                          VRM
                        </th>
                        <th className="px-6 py-6 text-left text-xs font-medium text-gray uppercase tracking-wider">
                          Case Reference
                        </th>
                        <th className="hidden md:table-cell px-6 py-6 text-left text-xs font-medium text-gray uppercase tracking-wider">
                          PCN Number
                        </th>
                        <th className="hidden lg:table-cell px-6 py-6 text-left text-xs font-medium text-gray uppercase tracking-wider">
                          Debtor Name
                        </th>
                        <th className="hidden lg:table-cell px-6 py-6 text-left text-xs font-medium text-gray uppercase tracking-wider">
                          Debtor Address
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      <tr
                        onClick={() => handleRowClick(fileIndex)}
                        className="cursor-pointer hover:bg-gray-100"
                      >
                        <td className="px-6 py-4 whitespace-nowrap">{file.VRM}</td>
                        <td className="px-6 py-4 whitespace-nowrap">{file.CaseReference}</td>
                        <td className="hidden md:table-cell px-6 py-4 whitespace-nowrap">{file.PCNNumber}</td>
                        <td className="hidden lg:table-cell px-6 py-4 whitespace-nowrap">{file.DebtorName}</td>
                        <td className="hidden lg:table-cell px-6 py-4 whitespace-nowrap">{file.Debtor_Address}</td>
                      </tr>
                    </tbody>
                  </table>
                ))}
              </div>
            </div>
          ))}
        </div>
      )}

      {/* Render modal */}
      {selectedRow && (
        <div className="fixed inset-0 z-50 overflow-auto bg-gray-800 bg-opacity-50 flex justify-center items-center">
          <div className="relative p-8 bg-white w-1/2 rounded-lg">
            <div className="absolute top-0 right-0">
              <button
                className="text-red-500 mt-10 m-6 hover:text-gray-700"
                onClick={closeModal}
              >
                Close
              </button>
            </div>

            <h2 className="text-lg text-orange-400 font-semibold mb-4">
              The complete details
            </h2>

            {selectedRow && (
              <CompleteDetails
                selectedRow={selectedRow}
                isDropdownOpen={isDropdownOpen}
                selectedLocation={selectedLocation}
                setIsDropdownOpen={setIsDropdownOpen}
                handleAssignTask={handleAssignTask}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
}

import React, { useEffect, useState, useRef } from "react";
import { FaBell } from "react-icons/fa"; 
import Tasks from "./Tasks";
import { useAuthStore } from "../store/store";
import TasksCompleted from "./TasksCompleted";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import AvailableTasks from "../accountUser/AvailableTasks";
// import Navbar from "../components/Navbar";

export default function UserDashboards() {
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState("dashboard");
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [notificationCount, setNotificationCount] = useState(0); 
  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);
  const hasWarnedRef = useRef(false);

  const {
    user,
    setIsLoggedIn,
    userLogOutUrl,
    setUser,
    clearAuthToken,
    isLoggedIn,
  } = useAuthStore();
  const [showConfirmation, setShowConfirmation] = useState(false);

  useEffect(() => {
    if (!isLoggedIn && !hasWarnedRef.current) {
      navigate("/signIn");
      toast.warn("Please login to access your dashboard.", {
        position: "top-left",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      hasWarnedRef.current = true;
    }
  }, [isLoggedIn, navigate]);

  const userId = user._id;

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const response = await axios.get(`http://localhost:7000/api/v1/user/notifications`, {
          params: { userId },
        });
        
        // Filter out unread notifications
        const unreadNotifications = response.data.filter(
          (notification) => !notification.read
        );
        
        // Set the count of unread notifications
        setNotificationCount(unreadNotifications.length);
      } catch (error) {
        console.error("Error fetching notifications:", error);
      }
    };

    fetchNotifications();
  }, [userId]);

  const logout = async () => {
    try {
      const authToken = localStorage.getItem("authToken");

      const res = await axios.get(userLogOutUrl, {
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });

      if (res.status === 200) {
        clearAuthToken();
        setIsLoggedIn(false);
        navigate("/signIn");

        toast.success("Successfully logged out", {
          position: "top-left",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    } catch (err) {
      console.error("Error logging out:", err);
      toast.error("Failed to log out. Please try again later.", {
        position: "top-left",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };

  const handleLogout = () => setShowConfirmation(true);
  const confirmLogout = () => logout();
  const cancelLogout = () => setShowConfirmation(false);

  const handleNotificationClick = async () => {
    // Mark all notifications as read
    try {
      await axios.post(`http://localhost:7000/api/v1/user/notifications/mark-read`, {
        userId,
      });
      setNotificationCount(0); // Reset the notification count after marking as read
    } catch (error) {
      console.error("Error marking notifications as read:", error);
    }
    
    navigate("/notifications");
  };

  return (
    <div className="flex flex-col lg:flex-row min-h-screen">
      <div className="w-full lg:w-64 bg-gray-900">
        <nav className={`p-2 ${isMenuOpen ? "block" : "hidden"} lg:block`}>
          <ul>
            <li
              className={`cursor-pointer text-secondary p-2 ${
                selectedTab === "dashboard" ? "bg-secondaryp" : ""
              }`}
              onClick={() => setSelectedTab("dashboard")}
            >
              Dashboard
            </li>
            <li
              className={`cursor-pointer text-secondary p-2 ${
                selectedTab === "TasksCompleted" ? "bg-secondaryp" : ""
              }`}
              onClick={() => setSelectedTab("TasksCompleted")}
            >
              Tasks Completed
            </li>
            <li
              className={`cursor-pointer text-secondary p-2 ${
                selectedTab === "AvailableTasks" ? "bg-secondaryp" : ""
              }`}
              onClick={() => setSelectedTab("AvailableTasks")}
            >
              Available Tasks
            </li>
          </ul>
        </nav>
        <button
          onClick={handleLogout}
          className="bg-red-500 ml-2 my-5 text-white px-6 py-2 rounded-sm"
        >
          Logout
        </button>
      </div>

      <div className="flex-1 bg-white">
        <button
          className="lg:hidden absolute top-4 right-4 text-gray-200 focus:outline-none focus:text-white"
          onClick={toggleMenu}
        >
          {isMenuOpen ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16m-7 6h7"
              />
            </svg>
          )}
        </button>

        <div className="bg-white p-4 md:py-10 border-b-[1.5px] border-orange-200 flex justify-between items-center pr-10">
          <div className="flex items-center">
            <h1 className="text-sm text-center text-gray-800">Welcome👋</h1>
            <h1 className="text-center font-semibold text-lg border bg-orange-100 border-orange-300 p-4 px-10 ml-3 text-gray-800 rounded-md">
              {user?.firstName} {user?.lastName}
            </h1>
          </div>
          <div className="relative">
            <FaBell
              className="text-gray-800 text-3xl cursor-pointer"
              onClick={handleNotificationClick}
            />
            {notificationCount > 0 && (
              <span className="absolute p-2 -top-1 -right-1 bg-red-500 text-white rounded-full w-4 h-4 flex items-center justify-center text-xs">
                {notificationCount}
              </span>
            )}
          </div>
        </div>

        <div className="">
          {selectedTab === "dashboard" && (
            <div className="p-5">
              <Tasks />
            </div>
          )}
          {selectedTab === "TasksCompleted" && (
            <div className="">
              <TasksCompleted />
            </div>
          )}
          {selectedTab === "AvailableTasks" && (
            <div className="p-5">
              <AvailableTasks />
            </div>
          )}
        </div>
      </div>

      {showConfirmation && (
        <div
          style={{ zIndex: 3 }}
          className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-75"
        >
          <div className="bg-white p-8 rounded-lg shadow-lg">
            <p className="text-sm font-medium text-center text-gray-800 mb-4">
              Are you sure you want to logout?
            </p>
            <div className="justify-center flex items-center">
              <img
                className="h-60 ml-4 rounded-xl"
                src={require("../images/logout.jpg")}
                alt="Logout confirmation"
              />
            </div>
            <div className="flex justify-between pt-8">
              <button
                onClick={confirmLogout}
                className="bg-red-500 hover:bg-red-300 transition duration-300 ease-in-out text-white w-full px-4 py-3 rounded-md mr-2"
              >
                Continue
              </button>
              <button
                onClick={cancelLogout}
                className="bg-gray-800 hover:bg-gray-600 transition duration-300 ease-in-out text-white w-full px-4 py-3 rounded-md"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

import React from "react";
import axios from "axios";
import { useAuthStore } from "../store/store";

export default function CommentList({ comments, taskId, onCommentAdded }) {
  
  const { user, tasks } = useAuthStore();
  
  const handleDeleteComment = async (commentId) => {
    try {
      const endpoint = `${tasks}/${taskId}/comments/${commentId}`;
      const isAdmin = user.role === "admin"; 
 
      const response = await axios.delete(endpoint, {
        data: { userId: user._id, isAdmin }
      });

      if (response.status === 200) {
        alert("Comment deleted successfully.");
        onCommentAdded();
      }
    } catch (error) {
      console.error("Error deleting comment:", error);
      alert("Failed to delete comment.");
    }
  };

  return (
    <div className="mt-6">
      <h3 className="text-lg font-medium text-gray-600">Comments</h3>
      <div className="overflow-x-auto mt-4">
        <table className="min-w-full border-gray-400 rounded-lg">
          <thead className="bg-orange-100">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Body</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Date</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">User Email</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Attachments</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
            </tr>
          </thead>
          {comments.length > 0 && (
            <tbody className="bg-white divide-y divide-gray-200">
              {comments.map((comment, index) => (
                <tr key={index}>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-700">{comment.body}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-700">{new Date(comment.date).toLocaleString()}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-700">{comment.email}</td> {/* Display the user's email */}
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-700">
                    {comment.attachments.length > 0 ? (
                      comment.attachments.map((attachment, index) => (
                        <a key={index} href={attachment.filePath} target="_blank" rel="noopener noreferrer" className="text-blue-500 underline mr-2">
                          {attachment.fileName}
                        </a>
                      ))
                    ) : (
                      <span>No attachments</span>
                    )}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-700">
                    {(comment.user === user._id || user.role === "admin") && (
                      <button
                        onClick={() => handleDeleteComment(comment._id)}
                        className="text-red-500 hover:text-red-700"
                      >
                        Delete
                      </button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          )}
        </table>
        {comments.length === 0 && (
          <p className="mt-4 text-sm text-gray-500">No comments available.</p>
        )}
      </div>
    </div>
  );
}
